export const useContentSections = () => {
  return {
    'shared.logo-block': 'BlockLogos',
    'shared.usp-list': 'BlockUsps',
    'shared.cards-block': 'BlockCards',
    'shared.showcase-block': 'BlockShowcase',
    'shared.text-block': 'BlockText',
    'shared.highlights-block': 'BlockHighlights',
    'shared.articles-block': 'BlockArticles',
    'shared.cta-block': 'BlockCtas',
    'shared.faq-block': 'BlockFaqs',
    'shared.bricks-block': 'BlockBricks',
    'shared.features-block': 'BlockFeatures',
    'shared.how-it-works': 'BlockHowItWorks',
    'shared.stats-block': 'BlockStats',
    'shared.content': 'ElementContent',
    'shared.image': 'ElementImage',
    'shared.quote': 'ElementQuote',
    'shared.hero': 'ElementHero',
    'shared.video-embed': 'ElementVideo',
    'shared.info-highlight': 'ElementHighlight',
    'shared.product-block': 'ElementProduct'
  }
}